<template>
  <v-container>
    <v-row class="my-4">
      <v-col>
        <div class="text-h5" color="#000000DE">
          <v-icon left color="#000000DE">mdi-account</v-icon>
          アカウント
        </div>
      </v-col>
    </v-row>
    <div class="justify-left" style="width: 100%;">
      <v-card class="elevation-0 my-8 pb-2">
        <v-card-title>ユーザー詳細</v-card-title>
        <v-simple-table :loading="loading" class="text--secondary">
          <template v-slot:default>
            <tbody v-if="!$vuetify.breakpoint.xs">
              <tr v-for="item in items" :key="item.name">
                <th class="text--secondary font-weight-bold">{{ item.caption }}</th>
                <td style="text-align: left;">{{ item.data }}</td>
              </tr>
            </tbody>
            <v-container v-else>
              <v-row v-for="item in items" :key="item.name">
                <v-col cols=12 class="text--secondary font-weight-bold">{{ item.caption }}</v-col>
                <v-col cols=12 class="text-caption">{{ item.data }}</v-col>
                <v-col cols=11 class="mx-auto" style="border-top: 1px solid gray; opacity: 0.5;"></v-col>
              </v-row>
            </v-container>
          </template>
        </v-simple-table>
        <v-card-actions class="d-flex flex-wrap justify-left">
          <v-row>
            <v-col cols="12" md="4" lg="4">
              <v-btn outlined block color="primary" to="/change_password">
                パスワードの変更
              </v-btn>
            </v-col>
            <v-col cols="12" md="4" lg="4" v-if="this.$store.state.user_model.userData.status == 0">
              <v-btn outlined block color="primary" to="withdraw">
                退会
              </v-btn>
            </v-col>
            <v-col cols="12" md="4" lg="4">
              <v-btn outlined block @click="dialog = true">
                利用規約の確認
              </v-btn>
            </v-col>
            <v-col cols="12" md="4" lg="4">
              <v-switch v-model="isLoginMail" :label="`サインイン通知`" @click="onToggleChange" color="primary"
                :disabled="loading" class="mt-0"></v-switch>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </div>
    <v-dialog v-model="dialog" max-width="600px" :max-height="$vuetify.breakpoint.xs ? '100%' : '400'"
      :fullscreen="$vuetify.breakpoint.xs" style="z-index: 2100;">
      <DialogTerms :max-height="$vuetify.breakpoint.xs ? '100%' : '400'" v-on:closeTermsEmit="close" />
    </v-dialog>
  </v-container>
</template>

<script>
import commonMixin from '@/mixins/commonMixin.js';
import { mapState } from 'vuex';
const DialogTerms = () => import('@/components/DialogTerms.vue');

export default {
  components: { DialogTerms },
  name: 'Account',
  mixins: [commonMixin],
  data: () => ({
    dialog: false,
    loading: false,
    isLoginMail: false,
    items: [
      { caption: "ユーザー名", data: "" },
      { caption: "契約プラン", data: "" },
      { caption: "最終サインイン日時", data: "" },
    ],
    itemList: ["userId", "status", "lastLogin"],
    itemNameList: ["ユーザー名", "契約プラン", "最終サインイン日時"],
    statusType: ["Free", "Standard", "Enterprise", "Unlimited"],
  }),
  async created() {
    await this.getData();
    this.isLoginMail = this.isLoginNotification;
  },
  computed: {
    isUser: function () {
      return this.$store.state.user_model.userData.userId;
    },
    ...mapState({
      isLoginNotification: (state) => state.user_model.userData.isLoginNotification,
    }),
  },
  watch: {
    isUser() {
      this.getData();
    },
    isLoginNotification() {
      this.isLoginMail = this.isLoginNotification;
    },
  },
  methods: {
    getData: async function () {
      if (this.$store.state.user_model.userData.userId) {
        this.items = [];
        let res = this.$store.state.user_model.userData;
        if (process.env.NODE_ENV === 'development') {
          console.log(res);
        }
        for (let i = 0; i < this.itemList.length; i++) {
          let item = this.itemList[i];
          let caption = this.itemNameList[i];
          let data = "";
          if (item == "userId") data = res[item];
          else if (item == "status") data = this.statusType[res[item]];
          else if (item == "lastLogin") {
            data = this.formatDate(res[item]);
          }
          this.items.push({ caption: caption, data: data });
        }
        this.loading = false;
      } else {
        this.loading = true;
      }
    },
    onToggleChange() {
      this.loading = true;
      this.$store
        .dispatch("changeLoginNotification", this.$store.state.auth.login)
        .then(() => {
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    close() {
      this.dialog = false;
    },
  },
};
</script>
