export default {
    data() {
        return {
            class_types: {
                Classification: "多項分類",
                Regression: "回帰分析",
                Forecast: "時系列予測",
            },
        };
    },
    computed: {
        projectName() {
            return this.$store.state.user_model.projectName;
        },
        projectClassType() {
            return this.$store.state.user_model.projectClassType;
        },
    },
    methods: {
        limitString(str, maxLength) {
            if (str.length > maxLength) {
                return str.slice(0, maxLength) + '...';
            }
            return str;
        },
        getClassificationIcon(classification) {
            switch (classification) {
                case '回帰分析':
                    return 'mdi-chart-line';
                case '多項分類':
                    return 'mdi-chart-pie';
                case '時系列予測':
                    return 'mdi-chart-timeline-variant';
                default:
                    return '';
            }
        },
        getClassByType(type) {
            switch (type) {
                case "回帰分析":
                    return "green lighten-2";
                case "多項分類":
                    return "orange lighten-2";
                case "時系列予測":
                    return "blue lighten-2";
                default:
                    return "";
            }
        },
        formatDate(dateTime) {
            const dateObj = new Date(dateTime);
            const year = dateObj.getFullYear();
            const month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
            const day = ("0" + dateObj.getDate()).slice(-2);
            const hours = ("0" + dateObj.getHours()).slice(-2);
            const minutes = ("0" + dateObj.getMinutes()).slice(-2);
            const seconds = ("0" + dateObj.getSeconds()).slice(-2);

            return `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;
        },
    }
}

